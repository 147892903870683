import { APPCONFIG } from './app.config';

const BASE_URL = APPCONFIG.baseUrl;
const API_VERSION = APPCONFIG.apiVersion;
const APP_NAME = APPCONFIG.appName;

export type IAPICONFIG = {
	[key: string]: string;
};

export const APICONFIG: IAPICONFIG = {
	PROFILE_API: `${BASE_URL}${API_VERSION}/${APP_NAME}/user-profile/me/`,
	BANNER_API: `${BASE_URL}${API_VERSION}/${APP_NAME}/dashboard-banners`,
	MEETNG_API: `${BASE_URL}${API_VERSION}/meetings/${APP_NAME}/dashboard-meetings/?page=1&limit=10`,
	QUARTER_SUMMARY_API: `${BASE_URL}${API_VERSION}/${APP_NAME}/dashboard-quarter-summary/`,
	COMPETITION_CONVERSION_USAGE: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/reason-for-competition`,
	COMPETITION_CONVERSION_GRADE: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/grade`,
	COMPETITION_CONVERSION_PRODUCT_RECOMMENDED: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/competition-conversion`,
	BASIC_DETAILS: `${BASE_URL}${API_VERSION}/${APP_NAME}/lead-user/`,
	EDIT_BASIC_DETAILS: `${BASE_URL}${API_VERSION}/${APP_NAME}/account/`,
	TOWN_LIST_DETAILS: `${BASE_URL}${API_VERSION}/${APP_NAME}/town/`,
	IMR_CODE_LIST_DETAILS: `${BASE_URL}${API_VERSION}/${APP_NAME}/imr-code/`,
	ADOPTED_CONTRACTOR_API: `${BASE_URL}${API_VERSION}/${APP_NAME}/adopted-contractors/`,
	LEAD_USER_API: `${BASE_URL}${API_VERSION}/${APP_NAME}/lead-user/`,
	ALL_END_USER_API: `${BASE_URL}${API_VERSION}/${APP_NAME}/all-end-user/`,
	CLUB_DROPDOWN: `${BASE_URL}${API_VERSION}/${APP_NAME}/club-dropdown/`,
	DEALERS_LIST: `${BASE_URL}${API_VERSION}/${APP_NAME}/dealer/`,
	ADOPTED_CONTRACTOR_DETAILS: `${BASE_URL}${API_VERSION}/${APP_NAME}/adopted-contractors`,
	ALL_ENDUSER_CONTRACTOR_DETAILS: `${BASE_URL}${API_VERSION}/${APP_NAME}/all-end-user`,
	SITE_LEAD: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/site-lead/`,
	ALL_CALENDAR_DETAILS: `${BASE_URL}${API_VERSION}/meetings/${APP_NAME}/calendar`,
	TASKLIST_PRODUCTS: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/product-recommendations`,
	SITE_VISIT: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/visit/`,
	ALL_SITE_SUMMARY_LIST: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/site-summary/`,
	AVAILABLE_PRODUCTS_LIST: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/products/`,
	CLOSE_LEADS: `${BASE_URL}${API_VERSION}/${APP_NAME}/closelead`,
	PHONE_NUMBER: `${BASE_URL}${API_VERSION}/${APP_NAME}/validate_phone`,
	GLUE_POTENTIAL: `${BASE_URL}${API_VERSION}/${APP_NAME}/gluepotential`,
	ALL_EVENT_DETAILS: `${BASE_URL}${API_VERSION}/meetings/${APP_NAME}/events`,
	VISIT_FEEDBACK: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/visit-feedback/`,
	IMAGE_GALLERY: `${BASE_URL}/apis/v.1.0/fv/image`,
	USER_LIST: `${BASE_URL}${API_VERSION}/${APP_NAME}/pidilite-user`,
	PRESIGNED_URL: `${BASE_URL}${API_VERSION}/common/${APP_NAME}/get-pre-signed-url/`,
	SITE_SUMMARY_LISTING: `${BASE_URL}${API_VERSION}/site-leads/${APP_NAME}/site-summary/`,
	REDEMPTION_HISTORY: `${BASE_URL}/apis/v.1.0/capillary/get-banking-history/`,
	MEETINGS_DETAILS: `${BASE_URL}${API_VERSION}/meetings/${APP_NAME}/meetings`,
	VIDEO_GALLERY: `${BASE_URL}${API_VERSION}/gallery/wwa/video`,
	CALCULATOR_PRODUCT_LIST: `${BASE_URL}/apis/v.1.0/calculator/`,
	CURRENT_SLAB: `${BASE_URL}${API_VERSION}/wwa/slab-claculator`,
	CUSTOMER_HISTORY: `${BASE_URL}/apis/v.1.0/capillary/get-customer`,
	VALIDATE_BARCODE: `${BASE_URL}/apis/v.1.0/capillary/barcode-scan`,
	POINTS_DASHBOARD: `${BASE_URL}/apis/v.1.0/capillary/get-customer-lookup`,
	BANKING_DETAIL: `${BASE_URL}${API_VERSION}/wwa/banking-data`,
	END_USER_NORMS_COUNT: `${BASE_URL}/apis/v.2.0/common/fv/enroll-user-count`,
	END_USER_NORMS: `${BASE_URL}/apis/v.2.0/common/fv/end-user-norm/`,
	CLUB_MEMBER_LIST: `${BASE_URL}/apis/v.2.0/common/fv/club-member-list`,
	NON_REPEAT_ATTENDED: `${BASE_URL}${API_VERSION}/meetings/${APP_NAME}/non-repeat-attendee/`,
	MEETING_TYPE: `${BASE_URL}${API_VERSION}/meetings/${APP_NAME}/meeting-type/`,
	PRODUCT_USAGE: `${BASE_URL}${API_VERSION}/wwa/product-usage/`,
	GET_CONTRACTOR_REDEMPTION_HISTORY: `${BASE_URL}${API_VERSION}/wwa/contractor-redemption-history/`,
	ADD_SITE_STATUS: `${BASE_URL}${API_VERSION}/site-leads/wwa/site-status/`,
	SITE_LEAD_OVERVIEW: `${BASE_URL}/apis/v.3.0/site-leads/wwa/site-summary/`,
	DEALER_VISITS: `${BASE_URL}/apis/v.3.0/dealer-visit/wwa/crud/`,
	DEALER_VISIT_PRODUCTS: `${BASE_URL}/apis/v.3.0/dealer-visit/wwa/product/`,
	FPC_DETAILS: `${BASE_URL}/apis/v.3.0/site-leads/wwa/fpc-tier-status/`,
	CLUB_RECOMMENDATION: `${BASE_URL}${API_VERSION}/meetings/wwa/club-recommendation/`,
	PCMS_REGISTRATION: `${BASE_URL}${API_VERSION}/pcms/${APP_NAME}/all-pcms/`,
	PCMS: `${BASE_URL}${API_VERSION}/pcms/${APP_NAME}`,
	MEMBER_NAME_DETAILS: ` ${BASE_URL}${API_VERSION}/dealer-visit/wwa/get-member-name/`,
	SITE_LEAD_DETAILS: ` ${BASE_URL}/apis/v.3.0/site-leads/wwa/site-lead-detail/`,
	SCHEME_DETAILS: `${BASE_URL}${API_VERSION}/common/wwa/scheme`,
	LOGIN: `${BASE_URL}/apis/v.1.0/login`,
	UPDATE_SITE_LEAD: `${BASE_URL}${API_VERSION}/site-leads/wwa/site-lead/`,
	GET_MEMBERSHIP_NUMBER: `${BASE_URL}/apis/v.1.0/capillary/get-membershipno`,
	ONE_TO_ONE_MEETINGS_LIST: `${BASE_URL}${API_VERSION}/meetings/wwa/one-to-one-meetings`,
	LOGOUT: `${BASE_URL}${API_VERSION}/common/wwa/logout/`,
	END_USER_LIST: `${BASE_URL}${API_VERSION}/meetings/wwa/end-user-list/`,
	WSS_TERRITORY_LIST: `${BASE_URL}${API_VERSION}/meetings/wwa/wss-territory/`,
	PRIMARY_DEALERS_LIST: `${BASE_URL}${API_VERSION}/meetings/wwa/dealer-details-list/`,
	ADD_QUICK_ENDUSER: `${BASE_URL}${API_VERSION}/meetings/wwa/quick-end-user/`,
	END_USER_CATEGORY_LIST: `${BASE_URL}${API_VERSION}/meetings/wwa/end-user-category/`,
	FM_CONTENT: `${BASE_URL}${API_VERSION}/wwa/fm-media-content/`,
	BIRTHDAY_POP_UP: `${BASE_URL}/apis/v.3.0/common/wwa/birthday/`,
	TSI_IMAGE_UPLOAD: `${BASE_URL}${API_VERSION}/dealer-visit/wwa/tsi-image-upload/`,
	BDE_OF_IMR: `${BASE_URL}${API_VERSION}/wwa/bde-of-imr/`,
	FOCUS_PRODUCT: `${BASE_URL}${API_VERSION}/wwa/focus-product/`,
	END_USER_ELIGIBLE_FOR_VISIT: `${BASE_URL}${API_VERSION}/wwa/end-user-eligible-for-visit/`,
	CHECK_BDE_LEAVE: `${BASE_URL}${API_VERSION}/wwa/bde-on-leave/`,
	GET_SITE_VISIT_OTP: `${BASE_URL}/apis/v.2.0/common/site-visit-otp-verification/send-otp/`,
	VERIFY_SITE_OTP: `${BASE_URL}/apis/v.2.0/common/site-visit-otp-verification/verify-otp/`,
	SUPERVISOR_LIST_BY_CONTACT: `${BASE_URL}${API_VERSION}/wwa/supervisor-contractor/`,
	SUPERVISOR_ADD_UPDATE: `${BASE_URL}${API_VERSION}/wwa/supervisor/`,
	SUPERVISOR_BANKING_HISTORY: `${BASE_URL}${API_VERSION}/wwa/supervisor-banking-history/`,
	FACTORY_VISIT_LIST: `${BASE_URL}${API_VERSION}/site-leads/wwa/factory-visit/`,
	GET_KAE_STATUS: `${BASE_URL}${API_VERSION}/site-leads/wwa/get-kae-product-recommendation/`,
	GET_PRODUCT_RECOMMENDATION_LIST: `${BASE_URL}${API_VERSION}/site-leads/wwa/kae-product-recommendation/`,
	ADD_PRODUCT_RECOMMENDATION: `${BASE_URL}${API_VERSION}/site-leads/wwa/kae-product-recommendation/`,
	GET_CONTRACTORS_FPC_LIST: `${BASE_URL}${API_VERSION}/wwa/sub-contractor-list/`,
	ADD_SUBCONTRACTORS_DETAIL: `${BASE_URL}${API_VERSION}/wwa/sub-contractor-tagging/`,
	FCC_CHECKS_FPC_USER: `${BASE_URL}/apis/v.3.0/wwa/fcc-checks-fpc-user/`,
	FCC_USER_SLAB: `${BASE_URL}/apis/v.3.0/wwa/fcc-fpc-users-slab/`,
	FCC_ENROLL: `${BASE_URL}/apis/v.3.0/wwa/fcc-enroll-fpc-user/`,
	MEETINGS_MARK_ATTENDANCE: `${BASE_URL}${API_VERSION}/meetings/${APP_NAME}/meetings/`,
	GET_JHAROKHA_TOKEN: `${BASE_URL}/apis/v.2.0/common/jharokha-token-generate/`,
	PCMS_TOKEN: `${BASE_URL}/apis/v.2.0/pcms/mpower-token/`,
};
